import { Flex, styled, Text } from '@conteg/ui';
import { ReceiptLink } from 'components/receipt-link/receipt-link';
import { InfoText } from 'pages/events/info-text';
import { useTranslation } from 'react-i18next';
import {
  AllocationInspectedFragment,
  AllocationStartedFragment,
  AllocationTerminatedFragment,
  ContentDeallocatedFragment,
  ContentPickedUpFragment,
  ContentStockedInFragment,
  ContentStockedOutFragment,
  ContentStoredFragment,
  PaymentAbortInfoFragment,
  PaymentProcessedFragment,
  PaymentRejectedFragment,
  PaymentReversalProcessedFragment,
  PaymentReversalRejectedFragment,
  SubmissionCodeValidated,
  UnknownContentFoundFragment,
  VerifyPickUpAccessCredentialsSuccessFragment,
} from 'types/generated/graphql';
import { booleanToYesNo } from 'utils/boolean-to-yes-no/boolean-to-yes-no';

export type TableItem =
  | ContentStockedInFragment
  | ContentStockedOutFragment
  | ContentPickedUpFragment
  | ContentDeallocatedFragment
  | VerifyPickUpAccessCredentialsSuccessFragment
  | ContentStoredFragment
  | AllocationInspectedFragment
  | AllocationTerminatedFragment
  | AllocationStartedFragment
  | SubmissionCodeValidated
  | UnknownContentFoundFragment
  | PaymentAbortInfoFragment
  | PaymentProcessedFragment
  | PaymentRejectedFragment
  | PaymentReversalProcessedFragment
  | PaymentReversalRejectedFragment;

type Props = {
  event: TableItem;
};

export const AdditionalInfo = ({ event }: Props) => {
  const { t } = useTranslation();

  if (event.__typename === 'ContentStored') {
    return (
      <Container>
        <InfoText label={t('PhoneNumber')} value={event.phoneNumber} />
      </Container>
    );
  }

  if (event.__typename === 'AllocationStarted') {
    return (
      <Container>
        {event.clientCredentials?.map((credential, index) => (
          <InfoText
            key={index}
            label={
              credential.clientType === 'RECIPIENT'
                ? t('RecipientPhoneNumber')
                : credential.clientType === 'SENDER'
                  ? t('SenderPhoneNumber')
                  : t('PhoneNumber')
            }
            value={credential.phoneNumber}
          />
        ))}
        <InfoText label={t('Point')} value={event.pointAddress} />
        <InfoText
          label={t('StorageUnitTracking.StorageUnitNumber')}
          value={event.storageUnitNumber}
        />
      </Container>
    );
  }

  if (event.__typename === 'VerifyPickUpAccessCredentialsSuccess') {
    return (
      <Container>
        <InfoText label={t('PhoneNumber')} value={event.phoneNumber} />
        <InfoText label={t('Pin')} value={event.pinCode} />
        <InfoText label={t('Table.FirstName')} value={event.firstName} />
        <InfoText label={t('Table.LastName')} value={event.lastname} />
        <InfoText label={t('Table.Email')} value={event.email} />
      </Container>
    );
  }

  if (event.__typename === 'ContentStockedIn') {
    return (
      <Container>
        <InfoText
          label={t('Table.CourierIdentifier')}
          value={event.courierIdentifier}
        />
      </Container>
    );
  }

  if (
    event.__typename === 'AllocationInspected' ||
    event.__typename === 'AllocationTerminated' ||
    event.__typename === 'ContentStockedOut' ||
    event.__typename === 'ContentPickedUp'
  ) {
    return (
      <Container>
        <InfoText label={t('Table.FirstName')} value={event.firstName} />
        <InfoText label={t('Table.LastName')} value={event.lastname} />
        <InfoText label={t('Table.Email')} value={event.email} />
        {event.__typename === 'ContentPickedUp' && (
          <InfoText
            label={t('Table.EventSource')}
            value={event.email ? 'Maintenance web' : 'Workflow web'}
          />
        )}
        {event.__typename === 'ContentStockedOut' && (
          <InfoText
            label={t('Table.CourierIdentifier')}
            value={event.courierIdentifier}
          />
        )}
      </Container>
    );
  }

  if (event.__typename === 'SubmissionCodeValidated') {
    return (
      <Container>
        <InfoText
          label={t('Table.IsScanned')}
          value={t(booleanToYesNo(event.isScanned))}
        />
        <InfoText
          label={t('Table.IsSuccessful')}
          value={t(booleanToYesNo(event.isSuccessful))}
        />
      </Container>
    );
  }

  if (event.__typename === 'PaymentAbortInfo') {
    return (
      <Container>
        <InfoText label={t('Table.PointId')} value={event.pointId} />
        <InfoText label={t('Table.SystemNumber')} value={event.systemNumber} />
      </Container>
    );
  }

  if (event.__typename === 'PaymentProcessed') {
    return (
      <Container>
        <InfoText
          label={t('Table.PointId')}
          value={event.paymentInfo.pointId}
        />
        {event.paymentInfo?.reservationId && event.paymentInfo?.subjectId && (
          <Flex gap="1rem">
            <Text variant="highlight" content={`${t('Table.Receipt')}:`} />
            <ReceiptLink
              title={t('Table.ReceiptLink')}
              reservationId={event.paymentInfo.reservationId}
              subjectId={event.paymentInfo.subjectId}
            />
          </Flex>
        )}
      </Container>
    );
  }

  if (event.__typename === 'PaymentRejected') {
    return (
      <Container>
        <InfoText
          label={t('Table.PointId')}
          value={event.paymentInfo.pointId}
        />
        <InfoText
          label={t('Table.ReservationId')}
          value={event.paymentInfo.reservationId}
        />
        <InfoText
          label={t('Table.SubjectId')}
          value={event.paymentInfo.subjectId}
        />
        {event.paymentInfo.reservationId && event.paymentInfo.subjectId && (
          <Flex gap="1rem">
            <Text variant="highlight" content={`${t('Table.Receipt')}:`} />
            <ReceiptLink
              title={t('Table.ReceiptLink')}
              reservationId={event.paymentInfo.reservationId}
              subjectId={event.paymentInfo.subjectId}
            />
          </Flex>
        )}
      </Container>
    );
  }

  if (event.__typename === 'PaymentReversalProcessed') {
    return (
      <Container>
        <InfoText
          label={t('Table.PointId')}
          value={event.paymentInfo.pointId}
        />
        {event.paymentInfo?.reservationId && event.paymentInfo?.subjectId && (
          <Flex gap="1rem">
            <Text variant="highlight" content={`${t('Table.Receipt')}:`} />
            <ReceiptLink
              title={t('Table.ReceiptLink')}
              reservationId={event.paymentInfo.reservationId}
              subjectId={event.paymentInfo.subjectId}
            />
          </Flex>
        )}
      </Container>
    );
  }

  if (event.__typename === 'PaymentReversalRejected') {
    return (
      <Container>
        <InfoText
          label={t('Table.PointId')}
          value={event.paymentInfo.pointId}
        />
        <InfoText
          label={t('Table.ReservationId')}
          value={event.paymentInfo.reservationId}
        />
        <InfoText
          label={t('Table.SubjectId')}
          value={event.paymentInfo.subjectId}
        />
        {event.paymentInfo.reservationId && event.paymentInfo.subjectId && (
          <Flex gap="1rem">
            <Text variant="highlight" content={`${t('Table.Receipt')}:`} />
            <ReceiptLink
              title={t('Table.ReceiptLink')}
              reservationId={event.paymentInfo.reservationId}
              subjectId={event.paymentInfo.subjectId}
            />
          </Flex>
        )}
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;
`;
