import { Flex, Text } from '@conteg/ui';
import { InfoText } from 'pages/events/info-text';
import { useTranslation } from 'react-i18next';
import {
  AccessCredentialUser,
  CourierAccessCredentialsVerifiedFragment,
  StorageUnitStatusChangedFragment,
} from 'types/generated/graphql';

import { StorageUnitTrackingLockState } from './storage-unit-tracking-lock-state';

export type TableItem =
  | StorageUnitStatusChangedFragment
  | CourierAccessCredentialsVerifiedFragment;

type StorageUnitAdditionalInfoProps = {
  event: TableItem;
};

export const StorageUnitAdditionalInfo = ({
  event,
}: StorageUnitAdditionalInfoProps) => {
  const { t } = useTranslation();

  if (event.__typename === 'StorageUnitStatusChanged') {
    const { storageUnitName, lockStatus, accessCredential, user } = event;

    const renderAccessCredential = () => {
      if (!accessCredential) return null;

      let label = '';
      let value = '';

      switch (accessCredential.userType) {
        case AccessCredentialUser.Customer:
          label = t('StorageUnitAdditionalInfo.Customer');
          value = accessCredential.phoneNumber ?? '-';
          break;
        case AccessCredentialUser.Sender:
          label = t('StorageUnitAdditionalInfo.Sender');
          value = accessCredential.phoneNumber ?? '-';
          break;
        case AccessCredentialUser.Receiver:
          label = t('StorageUnitAdditionalInfo.Receiver');
          value = accessCredential.phoneNumber ?? '-';
          break;
        case AccessCredentialUser.Courier:
          label = t('StorageUnitAdditionalInfo.Courier');
          value = accessCredential.personIdentifier ?? '-';
          break;
        default:
          return null;
      }

      return <InfoText label={`${label}`} value={value} />;
    };

    const renderUserInfo = () => {
      if (!user) return null;

      const hasFullName = user.firstName && user.lastName;
      const label = t('StorageUnitAdditionalInfo.User');
      const value = hasFullName
        ? `${user.firstName} ${user.lastName} (${user.email ?? '-'})`
        : (user.email ?? '-');

      return <InfoText label={label} value={value} />;
    };

    return (
      <Flex gap="3rem">
        <InfoText
          label={t('StorageUnitAdditionalInfo.StorageUnitNumber')}
          value={storageUnitName}
        />
        <Flex gap="1rem">
          <Text
            variant="highlight"
            content={t('StorageUnitTracking.LockState')}
          />
          <StorageUnitTrackingLockState lockState={lockStatus} />
        </Flex>

        {renderAccessCredential()}
        {renderUserInfo()}
      </Flex>
    );
  }

  if (event.__typename === 'CourierAccessCredentialsVerified') {
    return (
      <Flex gap="3rem">
        <InfoText
          label={t('Table.CourierIdentifier')}
          value={
            event.personIdentifier ??
            t('StorageUnitTracking.NoPersonIdentifier')
          }
        />
        <InfoText label={t('Pin')} value={event.pinCode} />
      </Flex>
    );
  }

  return null;
};
