import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetcher } from '../../utils/gql/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: string; output: string };
  UUID: { input: string; output: string };
};

export enum AccessCredentialAction {
  Peek = 'PEEK',
  Pickup = 'PICKUP',
  StockIn = 'STOCK_IN',
  StockOut = 'STOCK_OUT',
  Store = 'STORE',
}

export type AccessCredentialDto = {
  __typename?: 'AccessCredentialDto';
  accessCredentialActions: Array<AccessCredentialAction>;
  id: Scalars['UUID']['output'];
  installationId?: Maybe<Scalars['UUID']['output']>;
  isArchived: Scalars['Boolean']['output'];
  personIdentifier?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pointId?: Maybe<Scalars['UUID']['output']>;
  projectId: Scalars['UUID']['output'];
  reservationId?: Maybe<Scalars['UUID']['output']>;
  serviceType: ServiceType;
  tenantSubjectId: Scalars['UUID']['output'];
  userType?: Maybe<AccessCredentialUser>;
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

export enum AccessCredentialUser {
  Courier = 'COURIER',
  Customer = 'CUSTOMER',
  Receiver = 'RECEIVER',
  Sender = 'SENDER',
}

export type AllocationInspected = {
  __typename?: 'AllocationInspected';
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type AllocationStarted = {
  __typename?: 'AllocationStarted';
  clientCredentials: Array<ClientCredentials>;
  contentEventType: ContentEventTypeEnum;
  installationTimezone?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Language>;
  ownerSubjectId: Scalars['UUID']['output'];
  pointAddress: Scalars['String']['output'];
  pointId: Scalars['UUID']['output'];
  projectId: Scalars['UUID']['output'];
  reservationExpiration: Scalars['DateTime']['output'];
  reservationId: Scalars['UUID']['output'];
  reservationTo: Scalars['DateTime']['output'];
  storageUnitNumber: Scalars['String']['output'];
  tenantSubjectId: Scalars['UUID']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
  trackingIdentifiers: Array<TrackingIdentifierModel>;
};

export type AllocationTerminated = {
  __typename?: 'AllocationTerminated';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export type ClientCredentials = {
  __typename?: 'ClientCredentials';
  clientType: ClientType;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pinCode?: Maybe<Scalars['String']['output']>;
};

export type ClientPhoneNumber = {
  __typename?: 'ClientPhoneNumber';
  clientType: ClientType;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export enum ClientType {
  Depositor = 'DEPOSITOR',
  Recipient = 'RECIPIENT',
  Sender = 'SENDER',
}

export type ContentDeallocated = {
  __typename?: 'ContentDeallocated';
  contentEventType: ContentEventTypeEnum;
  storageUnitId: Scalars['UUID']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
};

export enum ContentEventTypeEnum {
  Deallocated = 'DEALLOCATED',
  Inspected = 'INSPECTED',
  PaymentAbortInfo = 'PAYMENT_ABORT_INFO',
  PaymentProcessed = 'PAYMENT_PROCESSED',
  PaymentRejected = 'PAYMENT_REJECTED',
  PaymentReversalProcessed = 'PAYMENT_REVERSAL_PROCESSED',
  PaymentReversalRejected = 'PAYMENT_REVERSAL_REJECTED',
  PickedUp = 'PICKED_UP',
  Started = 'STARTED',
  StockedIn = 'STOCKED_IN',
  StockedOut = 'STOCKED_OUT',
  Stored = 'STORED',
  SubmissionCodeValidated = 'SUBMISSION_CODE_VALIDATED',
  Terminated = 'TERMINATED',
  UnknownContentFound = 'UNKNOWN_CONTENT_FOUND',
  VerifyAccessCredentialsSuccess = 'VERIFY_ACCESS_CREDENTIALS_SUCCESS',
}

export type ContentPickedUp = {
  __typename?: 'ContentPickedUp';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentStockedIn = {
  __typename?: 'ContentStockedIn';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  courierIdentifier?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentStockedOut = {
  __typename?: 'ContentStockedOut';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  courierIdentifier?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentStored = {
  __typename?: 'ContentStored';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pinCode?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentTracking =
  | AllocationInspected
  | AllocationStarted
  | AllocationTerminated
  | ContentDeallocated
  | ContentPickedUp
  | ContentStockedIn
  | ContentStockedOut
  | ContentStored
  | PaymentAbortInfo
  | PaymentProcessed
  | PaymentRejected
  | PaymentReversalProcessed
  | PaymentReversalRejected
  | SubmissionCodeValidated
  | UnknownContentFound
  | VerifyPickUpAccessCredentialsSuccess;

export type CourierAccessCredentialsVerified = {
  __typename?: 'CourierAccessCredentialsVerified';
  personIdentifier?: Maybe<Scalars['String']['output']>;
  pinCode?: Maybe<Scalars['String']['output']>;
  pointEventType: PointEventTypeEnum;
  timeStampUtc: Scalars['DateTime']['output'];
};

export enum Currency {
  Czk = 'CZK',
  Default = 'DEFAULT',
  Eur = 'EUR',
  Gbp = 'GBP',
  Ron = 'RON',
  Rub = 'RUB',
  Usd = 'USD',
}

export type ExportSmsDto = {
  __typename?: 'ExportSmsDto';
  messagesCount: Scalars['Int']['output'];
  pointId?: Maybe<Scalars['UUID']['output']>;
  pointRegistrationNumber?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['UUID']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  smsMessagesCount: Scalars['Int']['output'];
  tenantSubjectId?: Maybe<Scalars['UUID']['output']>;
  tenantSubjectName?: Maybe<Scalars['String']['output']>;
};

export enum Language {
  Cs = 'CS',
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  Sk = 'SK',
  Uk = 'UK',
}

export enum LockStatus {
  Closed = 'CLOSED',
  Failed = 'FAILED',
  Opened = 'OPENED',
}

export type PaginatedPointTrackingMessageDto = {
  __typename?: 'PaginatedPointTrackingMessageDto';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  items: Array<PointTracking>;
  pageIndex: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PaymentAbortInfo = {
  __typename?: 'PaymentAbortInfo';
  contentEventType: ContentEventTypeEnum;
  pointId?: Maybe<Scalars['String']['output']>;
  systemNumber: Scalars['Int']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
};

export type PaymentInfoDto = {
  __typename?: 'PaymentInfoDto';
  amount?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  pointId?: Maybe<Scalars['String']['output']>;
  reservationId?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['String']['output']>;
};

export type PaymentProcessed = {
  __typename?: 'PaymentProcessed';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  paymentInfo: PaymentInfoDto;
  timeStampUtc: Scalars['DateTime']['output'];
  trackingIdentifiers?: Maybe<Array<TrackingIdentifierModel>>;
};

export type PaymentRejected = {
  __typename?: 'PaymentRejected';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  paymentInfo: PaymentInfoDto;
  timeStampUtc: Scalars['DateTime']['output'];
  trackingIdentifiers?: Maybe<Array<TrackingIdentifierModel>>;
};

export type PaymentReversalProcessed = {
  __typename?: 'PaymentReversalProcessed';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  paymentInfo: PaymentInfoDto;
  timeStampUtc: Scalars['DateTime']['output'];
  trackingIdentifiers?: Maybe<Array<TrackingIdentifierModel>>;
};

export type PaymentReversalRejected = {
  __typename?: 'PaymentReversalRejected';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  paymentInfo: PaymentInfoDto;
  timeStampUtc: Scalars['DateTime']['output'];
  trackingIdentifiers?: Maybe<Array<TrackingIdentifierModel>>;
};

export enum PointEventTypeEnum {
  CourierAccessCredentialsVerified = 'COURIER_ACCESS_CREDENTIALS_VERIFIED',
  StorageUnitStatusChanged = 'STORAGE_UNIT_STATUS_CHANGED',
}

export type PointMessageDto = {
  __typename?: 'PointMessageDto';
  deactivationReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  installationId: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  isConfigured: Scalars['Boolean']['output'];
  isManualLayoutManagement: Scalars['Boolean']['output'];
  isOpenForEdit: Scalars['Boolean']['output'];
  pointIdentifier?: Maybe<Scalars['String']['output']>;
  pointPrefix?: Maybe<Scalars['String']['output']>;
  pointRegistrationNumber?: Maybe<Scalars['String']['output']>;
  pointSequenceNumber: Scalars['Int']['output'];
};

export type PointTracking =
  | CourierAccessCredentialsVerified
  | StorageUnitStatusChanged;

export type Query = {
  __typename?: 'Query';
  contentTracking?: Maybe<Array<Maybe<ContentTracking>>>;
  pointTracking?: Maybe<PaginatedPointTrackingMessageDto>;
  points?: Maybe<Array<Maybe<PointMessageDto>>>;
  sentSmsLogs: Array<ExportSmsDto>;
};

export type QueryContentTrackingArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  trackingIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPointTrackingArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  pointId?: InputMaybe<Scalars['UUID']['input']>;
  storageUnitName?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuerySentSmsLogsArgs = {
  pointId?: InputMaybe<Scalars['UUID']['input']>;
  projectId?: InputMaybe<Scalars['UUID']['input']>;
  smsSentAtFrom?: InputMaybe<Scalars['String']['input']>;
  smsSentAtTo?: InputMaybe<Scalars['String']['input']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
};

export enum ServiceType {
  BagToBox = 'BAG_TO_BOX',
  Delivery = 'DELIVERY',
  HandToHand = 'HAND_TO_HAND',
}

export type StorageUnitStatusChanged = {
  __typename?: 'StorageUnitStatusChanged';
  accessCredential?: Maybe<AccessCredentialDto>;
  accessCredentialId?: Maybe<Scalars['UUID']['output']>;
  installationId: Scalars['UUID']['output'];
  lockStatus: LockStatus;
  pointEventType: PointEventTypeEnum;
  pointId: Scalars['UUID']['output'];
  pointRegistrationNumber: Scalars['String']['output'];
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  storageUnitName: Scalars['String']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
  user?: Maybe<UserDto>;
  userId?: Maybe<Scalars['UUID']['output']>;
};

export type SubmissionCodeValidated = {
  __typename?: 'SubmissionCodeValidated';
  contentEventType: ContentEventTypeEnum;
  isScanned: Scalars['Boolean']['output'];
  isSuccessful: Scalars['Boolean']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
};

export type TrackingIdentifierModel = {
  __typename?: 'TrackingIdentifierModel';
  identifier?: Maybe<Scalars['String']['output']>;
};

export type UnknownContentFound = {
  __typename?: 'UnknownContentFound';
  actualStorageUnitId: Scalars['UUID']['output'];
  contentEventType: ContentEventTypeEnum;
  expectedStorageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type UserDto = {
  __typename?: 'UserDto';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  roleIds?: Maybe<Array<Scalars['UUID']['output']>>;
  subjectIds?: Maybe<Array<Scalars['UUID']['output']>>;
  userType: UserType;
};

export enum UserType {
  Registered = 'REGISTERED',
  ServiceAccount = 'SERVICE_ACCOUNT',
}

export type VerifyPickUpAccessCredentialsSuccess = {
  __typename?: 'VerifyPickUpAccessCredentialsSuccess';
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pinCode?: Maybe<Scalars['String']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type AllocationInspectedFragment = {
  __typename?: 'AllocationInspected';
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
  email?: string | null;
};

export type AllocationStartedFragment = {
  __typename?: 'AllocationStarted';
  storageUnitNumber: string;
  pointAddress: string;
  reservationId: string;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
  clientCredentials: Array<{
    __typename?: 'ClientCredentials';
    phoneNumber?: string | null;
    clientType: ClientType;
  }>;
};

export type AllocationTerminatedFragment = {
  __typename?: 'AllocationTerminated';
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
  email?: string | null;
};

export type ClientCredentialsFragment = {
  __typename?: 'ClientCredentials';
  phoneNumber?: string | null;
  clientType: ClientType;
};

export type ClientPhoneNumberFragment = {
  __typename?: 'ClientPhoneNumber';
  phoneNumber?: string | null;
  clientType: ClientType;
};

export type ContentDeallocatedFragment = {
  __typename?: 'ContentDeallocated';
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type ContentPickedUpFragment = {
  __typename?: 'ContentPickedUp';
  timeStampUtc: string;
  email?: string | null;
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
};

export type ContentStockedInFragment = {
  __typename?: 'ContentStockedIn';
  courierIdentifier?: string | null;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type ContentStockedOutFragment = {
  __typename?: 'ContentStockedOut';
  courierIdentifier?: string | null;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
  email?: string | null;
  firstName?: string | null;
  lastname?: string | null;
};

export type ContentStoredFragment = {
  __typename?: 'ContentStored';
  pinCode?: string | null;
  phoneNumber?: string | null;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type CourierAccessCredentialsVerifiedFragment = {
  __typename?: 'CourierAccessCredentialsVerified';
  personIdentifier?: string | null;
  pinCode?: string | null;
  pointEventType: PointEventTypeEnum;
  timeStampUtc: string;
};

export type ExportSmsFragment = {
  __typename?: 'ExportSmsDto';
  pointRegistrationNumber?: string | null;
  tenantSubjectName?: string | null;
  projectName?: string | null;
  smsMessagesCount: number;
  messagesCount: number;
};

export type PaymentAbortInfoFragment = {
  __typename?: 'PaymentAbortInfo';
  pointId?: string | null;
  systemNumber: number;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type PaymentInfoFragment = {
  __typename?: 'PaymentInfoDto';
  pointId?: string | null;
  subjectId?: string | null;
  reservationId?: string | null;
  amount?: string | null;
  currency?: Currency | null;
};

export type PaymentProcessedFragment = {
  __typename?: 'PaymentProcessed';
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
  paymentInfo: {
    __typename?: 'PaymentInfoDto';
    pointId?: string | null;
    subjectId?: string | null;
    reservationId?: string | null;
    amount?: string | null;
    currency?: Currency | null;
  };
  clientPhoneNumbers?: Array<{
    __typename?: 'ClientPhoneNumber';
    phoneNumber?: string | null;
    clientType: ClientType;
  }> | null;
  trackingIdentifiers?: Array<{
    __typename?: 'TrackingIdentifierModel';
    identifier?: string | null;
  }> | null;
};

export type PaymentRejectedFragment = {
  __typename?: 'PaymentRejected';
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
  paymentInfo: {
    __typename?: 'PaymentInfoDto';
    pointId?: string | null;
    subjectId?: string | null;
    reservationId?: string | null;
    amount?: string | null;
    currency?: Currency | null;
  };
  clientPhoneNumbers?: Array<{
    __typename?: 'ClientPhoneNumber';
    phoneNumber?: string | null;
    clientType: ClientType;
  }> | null;
  trackingIdentifiers?: Array<{
    __typename?: 'TrackingIdentifierModel';
    identifier?: string | null;
  }> | null;
};

export type PaymentReversalProcessedFragment = {
  __typename?: 'PaymentReversalProcessed';
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
  paymentInfo: {
    __typename?: 'PaymentInfoDto';
    pointId?: string | null;
    subjectId?: string | null;
    reservationId?: string | null;
    amount?: string | null;
    currency?: Currency | null;
  };
  clientPhoneNumbers?: Array<{
    __typename?: 'ClientPhoneNumber';
    phoneNumber?: string | null;
    clientType: ClientType;
  }> | null;
  trackingIdentifiers?: Array<{
    __typename?: 'TrackingIdentifierModel';
    identifier?: string | null;
  }> | null;
};

export type PaymentReversalRejectedFragment = {
  __typename?: 'PaymentReversalRejected';
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
  paymentInfo: {
    __typename?: 'PaymentInfoDto';
    pointId?: string | null;
    subjectId?: string | null;
    reservationId?: string | null;
    amount?: string | null;
    currency?: Currency | null;
  };
  clientPhoneNumbers?: Array<{
    __typename?: 'ClientPhoneNumber';
    phoneNumber?: string | null;
    clientType: ClientType;
  }> | null;
  trackingIdentifiers?: Array<{
    __typename?: 'TrackingIdentifierModel';
    identifier?: string | null;
  }> | null;
};

export type StorageUnitStatusChangedFragment = {
  __typename?: 'StorageUnitStatusChanged';
  storageUnitName: string;
  pointRegistrationNumber: string;
  pointId: string;
  installationId: string;
  lockStatus: LockStatus;
  pointEventType: PointEventTypeEnum;
  timeStampUtc: string;
  accessCredential?: {
    __typename?: 'AccessCredentialDto';
    phoneNumber?: string | null;
    personIdentifier?: string | null;
    userType?: AccessCredentialUser | null;
    serviceType: ServiceType;
  } | null;
  user?: {
    __typename?: 'UserDto';
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  } | null;
};

export type SubmissionCodeValidatedFragment = {
  __typename?: 'SubmissionCodeValidated';
  isSuccessful: boolean;
  isScanned: boolean;
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
};

export type TrackingIdentifierFragment = {
  __typename?: 'TrackingIdentifierModel';
  identifier?: string | null;
};

export type UnknownContentFoundFragment = {
  __typename?: 'UnknownContentFound';
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type VerifyPickUpAccessCredentialsSuccessFragment = {
  __typename?: 'VerifyPickUpAccessCredentialsSuccess';
  pinCode?: string | null;
  phoneNumber?: string | null;
  timeStampUtc: string;
  email?: string | null;
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
};

export type ContentTrackingQueryVariables = Exact<{
  trackingIdentifier?: InputMaybe<Scalars['String']['input']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type ContentTrackingQuery = {
  __typename?: 'Query';
  contentTracking?: Array<
    | {
        __typename: 'AllocationInspected';
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
        email?: string | null;
      }
    | {
        __typename: 'AllocationStarted';
        storageUnitNumber: string;
        pointAddress: string;
        reservationId: string;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
        clientCredentials: Array<{
          __typename?: 'ClientCredentials';
          phoneNumber?: string | null;
          clientType: ClientType;
        }>;
      }
    | {
        __typename: 'AllocationTerminated';
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
        email?: string | null;
      }
    | {
        __typename: 'ContentDeallocated';
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'ContentPickedUp';
        timeStampUtc: string;
        email?: string | null;
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'ContentStockedIn';
        courierIdentifier?: string | null;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'ContentStockedOut';
        courierIdentifier?: string | null;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
        email?: string | null;
        firstName?: string | null;
        lastname?: string | null;
      }
    | {
        __typename: 'ContentStored';
        pinCode?: string | null;
        phoneNumber?: string | null;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'PaymentAbortInfo';
        pointId?: string | null;
        systemNumber: number;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'PaymentProcessed';
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
        paymentInfo: {
          __typename?: 'PaymentInfoDto';
          pointId?: string | null;
          subjectId?: string | null;
          reservationId?: string | null;
          amount?: string | null;
          currency?: Currency | null;
        };
        clientPhoneNumbers?: Array<{
          __typename?: 'ClientPhoneNumber';
          phoneNumber?: string | null;
          clientType: ClientType;
        }> | null;
        trackingIdentifiers?: Array<{
          __typename?: 'TrackingIdentifierModel';
          identifier?: string | null;
        }> | null;
      }
    | {
        __typename: 'PaymentRejected';
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
        paymentInfo: {
          __typename?: 'PaymentInfoDto';
          pointId?: string | null;
          subjectId?: string | null;
          reservationId?: string | null;
          amount?: string | null;
          currency?: Currency | null;
        };
        clientPhoneNumbers?: Array<{
          __typename?: 'ClientPhoneNumber';
          phoneNumber?: string | null;
          clientType: ClientType;
        }> | null;
        trackingIdentifiers?: Array<{
          __typename?: 'TrackingIdentifierModel';
          identifier?: string | null;
        }> | null;
      }
    | {
        __typename: 'PaymentReversalProcessed';
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
        paymentInfo: {
          __typename?: 'PaymentInfoDto';
          pointId?: string | null;
          subjectId?: string | null;
          reservationId?: string | null;
          amount?: string | null;
          currency?: Currency | null;
        };
        clientPhoneNumbers?: Array<{
          __typename?: 'ClientPhoneNumber';
          phoneNumber?: string | null;
          clientType: ClientType;
        }> | null;
        trackingIdentifiers?: Array<{
          __typename?: 'TrackingIdentifierModel';
          identifier?: string | null;
        }> | null;
      }
    | {
        __typename: 'PaymentReversalRejected';
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
        paymentInfo: {
          __typename?: 'PaymentInfoDto';
          pointId?: string | null;
          subjectId?: string | null;
          reservationId?: string | null;
          amount?: string | null;
          currency?: Currency | null;
        };
        clientPhoneNumbers?: Array<{
          __typename?: 'ClientPhoneNumber';
          phoneNumber?: string | null;
          clientType: ClientType;
        }> | null;
        trackingIdentifiers?: Array<{
          __typename?: 'TrackingIdentifierModel';
          identifier?: string | null;
        }> | null;
      }
    | {
        __typename: 'SubmissionCodeValidated';
        isSuccessful: boolean;
        isScanned: boolean;
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
      }
    | {
        __typename: 'UnknownContentFound';
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'VerifyPickUpAccessCredentialsSuccess';
        pinCode?: string | null;
        phoneNumber?: string | null;
        timeStampUtc: string;
        email?: string | null;
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
      }
    | null
  > | null;
};

export type PointTrackingQueryVariables = Exact<{
  pointId?: InputMaybe<Scalars['UUID']['input']>;
  storageUnitName?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
}>;

export type PointTrackingQuery = {
  __typename?: 'Query';
  pointTracking?: {
    __typename?: 'PaginatedPointTrackingMessageDto';
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    totalCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    items: Array<
      | {
          __typename: 'CourierAccessCredentialsVerified';
          personIdentifier?: string | null;
          pinCode?: string | null;
          pointEventType: PointEventTypeEnum;
          timeStampUtc: string;
        }
      | {
          __typename: 'StorageUnitStatusChanged';
          storageUnitName: string;
          pointRegistrationNumber: string;
          pointId: string;
          installationId: string;
          lockStatus: LockStatus;
          pointEventType: PointEventTypeEnum;
          timeStampUtc: string;
          accessCredential?: {
            __typename?: 'AccessCredentialDto';
            phoneNumber?: string | null;
            personIdentifier?: string | null;
            userType?: AccessCredentialUser | null;
            serviceType: ServiceType;
          } | null;
          user?: {
            __typename?: 'UserDto';
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
          } | null;
        }
    >;
  } | null;
};

export type PointsQueryVariables = Exact<{ [key: string]: never }>;

export type PointsQuery = {
  __typename?: 'Query';
  points?: Array<{
    __typename?: 'PointMessageDto';
    id: string;
    pointSequenceNumber: number;
    pointRegistrationNumber?: string | null;
  } | null> | null;
};

export type SentSmsLogsQueryVariables = Exact<{
  smsSentAtFrom?: InputMaybe<Scalars['String']['input']>;
  smsSentAtTo?: InputMaybe<Scalars['String']['input']>;
}>;

export type SentSmsLogsQuery = {
  __typename?: 'Query';
  sentSmsLogs: Array<{
    __typename?: 'ExportSmsDto';
    pointRegistrationNumber?: string | null;
    tenantSubjectName?: string | null;
    projectName?: string | null;
    smsMessagesCount: number;
    messagesCount: number;
  }>;
};

export const AllocationInspectedFragmentDoc = `
    fragment AllocationInspected on AllocationInspected {
  firstName
  lastname
  contentEventType
  timeStampUtc
  email
}
    `;
export const ClientCredentialsFragmentDoc = `
    fragment ClientCredentials on ClientCredentials {
  phoneNumber
  clientType
}
    `;
export const AllocationStartedFragmentDoc = `
    fragment AllocationStarted on AllocationStarted {
  storageUnitNumber
  pointAddress
  reservationId
  clientCredentials {
    ...ClientCredentials
  }
  timeStampUtc
  contentEventType
}
    `;
export const AllocationTerminatedFragmentDoc = `
    fragment AllocationTerminated on AllocationTerminated {
  firstName
  lastname
  contentEventType
  timeStampUtc
  email
}
    `;
export const ContentDeallocatedFragmentDoc = `
    fragment ContentDeallocated on ContentDeallocated {
  timeStampUtc
  contentEventType
}
    `;
export const ContentPickedUpFragmentDoc = `
    fragment ContentPickedUp on ContentPickedUp {
  timeStampUtc
  email
  firstName
  lastname
  contentEventType
}
    `;
export const ContentStockedInFragmentDoc = `
    fragment ContentStockedIn on ContentStockedIn {
  courierIdentifier
  timeStampUtc
  contentEventType
}
    `;
export const ContentStockedOutFragmentDoc = `
    fragment ContentStockedOut on ContentStockedOut {
  courierIdentifier
  timeStampUtc
  contentEventType
  email
  firstName
  lastname
}
    `;
export const ContentStoredFragmentDoc = `
    fragment ContentStored on ContentStored {
  pinCode
  phoneNumber
  timeStampUtc
  contentEventType
}
    `;
export const CourierAccessCredentialsVerifiedFragmentDoc = `
    fragment CourierAccessCredentialsVerified on CourierAccessCredentialsVerified {
  personIdentifier
  pinCode
  pointEventType
  timeStampUtc
}
    `;
export const ExportSmsFragmentDoc = `
    fragment ExportSms on ExportSmsDto {
  pointRegistrationNumber
  tenantSubjectName
  projectName
  smsMessagesCount
  messagesCount
}
    `;
export const PaymentAbortInfoFragmentDoc = `
    fragment PaymentAbortInfo on PaymentAbortInfo {
  pointId
  systemNumber
  timeStampUtc
  contentEventType
}
    `;
export const PaymentInfoFragmentDoc = `
    fragment PaymentInfo on PaymentInfoDto {
  pointId
  subjectId
  reservationId
  amount
  currency
}
    `;
export const ClientPhoneNumberFragmentDoc = `
    fragment ClientPhoneNumber on ClientPhoneNumber {
  phoneNumber
  clientType
}
    `;
export const TrackingIdentifierFragmentDoc = `
    fragment TrackingIdentifier on TrackingIdentifierModel {
  identifier
}
    `;
export const PaymentProcessedFragmentDoc = `
    fragment PaymentProcessed on PaymentProcessed {
  paymentInfo {
    ...PaymentInfo
  }
  clientPhoneNumbers {
    ...ClientPhoneNumber
  }
  trackingIdentifiers {
    ...TrackingIdentifier
  }
  contentEventType
  timeStampUtc
}
    `;
export const PaymentRejectedFragmentDoc = `
    fragment PaymentRejected on PaymentRejected {
  paymentInfo {
    ...PaymentInfo
  }
  clientPhoneNumbers {
    ...ClientPhoneNumber
  }
  trackingIdentifiers {
    ...TrackingIdentifier
  }
  contentEventType
  timeStampUtc
}
    `;
export const PaymentReversalProcessedFragmentDoc = `
    fragment PaymentReversalProcessed on PaymentReversalProcessed {
  paymentInfo {
    ...PaymentInfo
  }
  clientPhoneNumbers {
    ...ClientPhoneNumber
  }
  trackingIdentifiers {
    ...TrackingIdentifier
  }
  contentEventType
  timeStampUtc
}
    `;
export const PaymentReversalRejectedFragmentDoc = `
    fragment PaymentReversalRejected on PaymentReversalRejected {
  paymentInfo {
    ...PaymentInfo
  }
  clientPhoneNumbers {
    ...ClientPhoneNumber
  }
  trackingIdentifiers {
    ...TrackingIdentifier
  }
  contentEventType
  timeStampUtc
}
    `;
export const StorageUnitStatusChangedFragmentDoc = `
    fragment StorageUnitStatusChanged on StorageUnitStatusChanged {
  storageUnitName
  pointRegistrationNumber
  pointId
  installationId
  lockStatus
  pointEventType
  timeStampUtc
  accessCredential {
    phoneNumber
    personIdentifier
    userType
    serviceType
  }
  user {
    firstName
    lastName
    email
  }
}
    `;
export const SubmissionCodeValidatedFragmentDoc = `
    fragment SubmissionCodeValidated on SubmissionCodeValidated {
  isSuccessful
  isScanned
  contentEventType
  timeStampUtc
}
    `;
export const UnknownContentFoundFragmentDoc = `
    fragment UnknownContentFound on UnknownContentFound {
  timeStampUtc
  contentEventType
}
    `;
export const VerifyPickUpAccessCredentialsSuccessFragmentDoc = `
    fragment VerifyPickUpAccessCredentialsSuccess on VerifyPickUpAccessCredentialsSuccess {
  pinCode
  phoneNumber
  timeStampUtc
  email
  firstName
  lastname
  contentEventType
}
    `;
export const ContentTrackingDocument = `
    query ContentTracking($trackingIdentifier: String, $tenantSubjectId: UUID, $from: DateTime, $to: DateTime, $pageSize: Int, $pageIndex: Int, $phoneNumber: String) {
  contentTracking(
    trackingIdentifier: $trackingIdentifier
    tenantSubjectId: $tenantSubjectId
    from: $from
    to: $to
    pageSize: $pageSize
    pageIndex: $pageIndex
    phoneNumber: $phoneNumber
  ) {
    __typename
    ... on ContentStockedIn {
      ...ContentStockedIn
    }
    ... on ContentStockedOut {
      ...ContentStockedOut
    }
    ... on ContentStored {
      ...ContentStored
    }
    ... on ContentPickedUp {
      ...ContentPickedUp
    }
    ... on ContentDeallocated {
      ...ContentDeallocated
    }
    ... on UnknownContentFound {
      ...UnknownContentFound
    }
    ... on AllocationInspected {
      ...AllocationInspected
    }
    ... on AllocationTerminated {
      ...AllocationTerminated
    }
    ... on SubmissionCodeValidated {
      ...SubmissionCodeValidated
    }
    ... on VerifyPickUpAccessCredentialsSuccess {
      ...VerifyPickUpAccessCredentialsSuccess
    }
    ... on PaymentAbortInfo {
      ...PaymentAbortInfo
    }
    ... on PaymentProcessed {
      ...PaymentProcessed
    }
    ... on PaymentRejected {
      ...PaymentRejected
    }
    ... on PaymentReversalProcessed {
      ...PaymentReversalProcessed
    }
    ... on PaymentReversalRejected {
      ...PaymentReversalRejected
    }
    ... on AllocationStarted {
      ...AllocationStarted
    }
  }
}
    ${ContentStockedInFragmentDoc}
${ContentStockedOutFragmentDoc}
${ContentStoredFragmentDoc}
${ContentPickedUpFragmentDoc}
${ContentDeallocatedFragmentDoc}
${UnknownContentFoundFragmentDoc}
${AllocationInspectedFragmentDoc}
${AllocationTerminatedFragmentDoc}
${SubmissionCodeValidatedFragmentDoc}
${VerifyPickUpAccessCredentialsSuccessFragmentDoc}
${PaymentAbortInfoFragmentDoc}
${PaymentProcessedFragmentDoc}
${PaymentInfoFragmentDoc}
${ClientPhoneNumberFragmentDoc}
${TrackingIdentifierFragmentDoc}
${PaymentRejectedFragmentDoc}
${PaymentReversalProcessedFragmentDoc}
${PaymentReversalRejectedFragmentDoc}
${AllocationStartedFragmentDoc}
${ClientCredentialsFragmentDoc}`;

export const useContentTrackingQuery = <
  TData = ContentTrackingQuery,
  TError = unknown,
>(
  variables?: ContentTrackingQueryVariables,
  options?: Omit<
    UseQueryOptions<ContentTrackingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<ContentTrackingQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ContentTrackingQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['ContentTracking']
        : ['ContentTracking', variables],
    queryFn: fetcher<ContentTrackingQuery, ContentTrackingQueryVariables>(
      ContentTrackingDocument,
      variables
    ),
    ...options,
  });
};

export const PointTrackingDocument = `
    query PointTracking($pointId: UUID, $storageUnitName: String, $from: DateTime, $to: DateTime, $pageSize: Int!, $pageIndex: Int!) {
  pointTracking(
    pointId: $pointId
    storageUnitName: $storageUnitName
    from: $from
    to: $to
    pageSize: $pageSize
    pageIndex: $pageIndex
  ) {
    items {
      __typename
      ... on CourierAccessCredentialsVerified {
        ...CourierAccessCredentialsVerified
      }
      ... on StorageUnitStatusChanged {
        ...StorageUnitStatusChanged
      }
    }
    pageIndex
    pageSize
    totalPages
    totalCount
    hasPreviousPage
    hasNextPage
  }
}
    ${CourierAccessCredentialsVerifiedFragmentDoc}
${StorageUnitStatusChangedFragmentDoc}`;

export const usePointTrackingQuery = <
  TData = PointTrackingQuery,
  TError = unknown,
>(
  variables: PointTrackingQueryVariables,
  options?: Omit<
    UseQueryOptions<PointTrackingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<PointTrackingQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<PointTrackingQuery, TError, TData>({
    queryKey: ['PointTracking', variables],
    queryFn: fetcher<PointTrackingQuery, PointTrackingQueryVariables>(
      PointTrackingDocument,
      variables
    ),
    ...options,
  });
};

export const PointsDocument = `
    query Points {
  points {
    id
    pointSequenceNumber
    pointRegistrationNumber
  }
}
    `;

export const usePointsQuery = <TData = PointsQuery, TError = unknown>(
  variables?: PointsQueryVariables,
  options?: Omit<UseQueryOptions<PointsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<PointsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<PointsQuery, TError, TData>({
    queryKey: variables === undefined ? ['Points'] : ['Points', variables],
    queryFn: fetcher<PointsQuery, PointsQueryVariables>(
      PointsDocument,
      variables
    ),
    ...options,
  });
};

export const SentSmsLogsDocument = `
    query SentSmsLogs($smsSentAtFrom: String, $smsSentAtTo: String) {
  sentSmsLogs(smsSentAtFrom: $smsSentAtFrom, smsSentAtTo: $smsSentAtTo) {
    ...ExportSms
  }
}
    ${ExportSmsFragmentDoc}`;

export const useSentSmsLogsQuery = <TData = SentSmsLogsQuery, TError = unknown>(
  variables?: SentSmsLogsQueryVariables,
  options?: Omit<
    UseQueryOptions<SentSmsLogsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SentSmsLogsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SentSmsLogsQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['SentSmsLogs'] : ['SentSmsLogs', variables],
    queryFn: fetcher<SentSmsLogsQuery, SentSmsLogsQueryVariables>(
      SentSmsLogsDocument,
      variables
    ),
    ...options,
  });
};
