import env from 'utils/env/env';

type ReceiptLinkProps = {
  title: string;
  reservationId: string;
  subjectId: string;
};

export const ReceiptLink = ({
  title,
  reservationId,
  subjectId,
}: ReceiptLinkProps) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`${env.VITE_PAYMENT_WEB_URL}?reservationId=${reservationId}&subjectId=${subjectId}`}
    >
      {title}
    </a>
  );
};
